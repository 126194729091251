import { fetchHelper } from '../../helpers/fetchHelper';
import { timeoutPromise } from '../../helpers/timeoutPromise';
import type { SlimAPI_PaymentsPlans } from './types';

const isServer = typeof sessionStorage === 'undefined';
const status: Record<string, 'PENDING' | 'RESOLVED' | 'FAILURE'> = {};

/**
 * @api /api/payments/v5/plans
 * @description Proton Slim API response containing all paid plans.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/payments/#tag/Plans/operation/get_payments-v4-plans
 */
export const getPaymentsPlans = async (currency?: string): Promise<SlimAPI_PaymentsPlans> => {
    // On server just request without caching
    if (isServer) {
        return request(currency);
    }

    // On client only request once and cache in session storage
    const key = `plans.${currency || 'AUTO'}`;
    const storedPlans = sessionStorage.getItem(key);

    if (!storedPlans && !status[key]) {
        status[key] = 'PENDING';
        const json = await request(currency).catch((error) => {
            status[key] = 'FAILURE';
            throw error;
        });
        sessionStorage.setItem(key, JSON.stringify(json));
        status[key] = 'RESOLVED';
        return json;
    }

    const getStoredPlans = async (): Promise<SlimAPI_PaymentsPlans> => {
        if (status[key] === 'PENDING') {
            await timeoutPromise(150);
            return await getStoredPlans();
        }

        const storedPlans = sessionStorage.getItem(key);
        if (!storedPlans) {
            throw new Error('[@protonme/slim-api] failed to load plans from session storage');
        } else {
            return JSON.parse(storedPlans);
        }
    };

    return await getStoredPlans();
};

const request = async (currency?: string): Promise<SlimAPI_PaymentsPlans> => {
    const urlSearchParams = new URLSearchParams({
        ...(currency && { currency }),
        fallback: 'true',
    });
    const urlSearchParamsString = urlSearchParams.size ? `?${urlSearchParams.toString()}` : '';
    const response = await fetchHelper(`/payments/v5/plans${urlSearchParamsString}`);

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_PaymentsPlans = await response.json();
    return json;
};
