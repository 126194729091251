import { locales as pmeLocales } from './locales-pme';
import { locales as vpnLocales } from './locales-vpn';

export type LocaleDetail = {
    /**
     * Code used on the website
     */
    code: string;

    /**
     * Hreflang
     */
    hrefLang: string;

    /**
     * Locale code used on Prismic
     */
    prismicCode: string;

    /**
     * Locale code used on WordPress
     */
    wpCode: string;

    /**
     * Flag code used to match flag icon
     */
    flagCode: string;

    /**
     * Is the blog considered translated in that lang
     */
    blog: boolean;

    /**
     * On Prismic, we have locales that should have almost all pages translated in that lang
     * And some other for which we just have some specific pages
     * All locales are in the list, only the one with this flag are supposed to have most pages translated
     */
    allPagesShouldBeTranslated: boolean;

    /**
     * Human readable language name
     */
    name: string;
};

export type LocaleBannerMessageEntity = {
    message: { text: string; crowdinContext: string };
    here: { text: string; crowdinContext: string };
};

export type LocaleBannerMessage = Record<string, LocaleBannerMessageEntity>;

const website = process.env.WEBSITE;

export const locales = (() => {
    switch (website) {
        case 'proton-me':
            return pmeLocales;
        case 'protonvpn-com':
            return vpnLocales;
        default:
            throw new Error(`[@protonme/localization]: No locales available for "${website}"`);
    }
})();

// Maps the LocaleDetail.code to the respective locale code for account
export const protonCodeToAccountMap: { [key: string]: string } = {
    'es-419': 'es',
};
