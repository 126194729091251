import type { ComponentType, ReactNode } from 'react';
import { useEffect } from 'react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { c } from 'ttag';
import { classnames } from '@protonme/ui/helpers/classnames';

export type NotificationType = 'info' | 'success' | 'error' | 'warning';

export type Props = {
    icon?: ComponentType<{ className?: string }>;
    type?: NotificationType;
    show: boolean;
    content: string | ReactNode;
    onClose?: () => void;
    onTimeOver?: () => void;
    timeout?: number;
};

const contentColorByType: Record<NotificationType, string> = {
    info: 'bg-blue-100 text-blue-900',
    success: 'bg-green-100 text-green-900',
    error: 'bg-red-100 text-red-900',
    warning: 'bg-amber-100 text-amber-900',
};

const iconColorByType: Record<NotificationType, string> = {
    info: 'bg-blue-900 text-white',
    success: 'bg-green-900 text-white',
    error: 'bg-red-900 text-white',
    warning: 'bg-amber-900 text-white',
};

export const Notification = ({
    type = 'info',
    icon: Icon,
    show,
    content,
    // Disabled because we want an empty function as fallback to reduce redundant code
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose = () => {},
    onTimeOver,
    timeout = 15_000,
}: Props) => {
    const showState = show;

    useEffect(() => {
        if (showState) {
            const timer = setTimeout(() => {
                onTimeOver && onTimeOver();
            }, timeout);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [onTimeOver, show, showState, timeout]);

    if (show) {
        return (
            <div
                className={classnames(
                    showState ? 'flex' : 'hidden',
                    'fixed bottom-12 z-notification items-center justify-between gap-x-6 rounded-lg p-6 shadow-l',
                    'start-4 w-3/4',
                    'md:start-12 md:w-1/2',
                    'xl:start-24 xl:w-1/3',
                    contentColorByType[type],
                )}
                role="status"
                aria-live="polite"
                data-test="notification"
            >
                {Icon && (
                    <div className={classnames('w-fit rounded-full p-4', iconColorByType[type])}>
                        <Icon className="h-4 w-4" />
                    </div>
                )}

                <div className="flex-1">{content}</div>

                <button onClick={onClose} data-test="close">
                    <span className="sr-only">{c('Action').t`Close notification`}</span>
                    <span aria-hidden="true">
                        <XMarkIcon className="h-8 w-8" />
                    </span>
                </button>
            </div>
        );
    }
    return null;
};
