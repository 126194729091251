import { type RefObject, useCallback, useMemo, useState } from 'react';
import { minify } from '../../helpers/minimalisticMinifier';
import type { RefHandler } from './types';

const key = 'ref';

const readValue = () => {
    try {
        return window.localStorage.getItem(key) || undefined;
    } catch {
        return undefined;
    }
};

const setValue = (value: string | undefined) => {
    try {
        if (value === undefined) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, value);
        }
    } catch {
        // abandon and forget
    }
};

export const useUrlWithRef = (inputUrl: URL): { url: URL; refHandler: RefHandler } => {
    const [stateRef, setStateRef] = useState<string | undefined>();

    const url = useMemo(() => {
        const url = new URL(inputUrl.href); // copy
        setStateRef(url.searchParams.get('ref') || undefined);
        url.searchParams.delete('ref');
        return url;
    }, [inputUrl.href]);

    const handler = useCallback(async () => {
        if (stateRef !== undefined) {
            setValue(stateRef);
        }
    }, [stateRef]);

    // Vanilla script to store ref in local storage on click
    // That way, the system works even with static Astro content without client JS (at least no client React)
    // Self excuting function to prevent global scope pollution while keeping access to currentScript
    const script = useMemo(
        () =>
            stateRef === undefined
                ? undefined
                : minify(`
                    (function(link) {
                        const handler = () => {
                            try { window.localStorage.setItem('${key}', '${stateRef}'); } catch {}
                        };
                        link.addEventListener('click', handler);
                        link._protonRefHandler = handler;
                    })(document.currentScript.parentElement)
                `),
        [stateRef],
    );

    // If an internal link using a native <a> is removed from the page
    // We want to cleanup the event handler not to create memory leaks
    // A ref to the handler used has been stored in link object itself
    const unmount = useCallback(
        (ref: RefObject<HTMLAnchorElement>) => () => {
            // eslint-disable-next-line
            const handler: () => void | undefined = (ref.current as any | null)?._protonRefHandler;
            if (handler) {
                document.removeEventListener('click', handler);
                // eslint-disable-next-line
                delete (ref.current as any)._protonRefHandler;
            }
        },
        [],
    );

    return useMemo(
        () => ({ url, refHandler: { script, handler, unmount } }),
        [url, script, handler, unmount],
    );
};

export const consumeUrlRef = () => {
    const ref = readValue();
    setValue(undefined);
    return ref;
};

export const useUrlRef = () => {
    return useMemo(() => readValue(), []);
};
