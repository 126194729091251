import type { Websites } from './getWebsite';

export const testIsB2B = (website: Websites, restParts: string[]) => {
    const isB2B =
        !!website &&
        ['proton-me', 'protonvpn-com'].includes(website) &&
        restParts.length >= 1 &&
        restParts[0] === 'business';
    return isB2B;
};
