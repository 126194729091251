import { useEffect, useState } from 'react';
import FlagIcon from '@heroicons/react/24/outline/FlagIcon';
import { defaultLocale, isLocaleCode, localeCodes } from '@protonme/localization';
import { isWP } from '@protonme/routing';
import { c } from 'ttag';
import { CookieSameSiteAttribute, setCookie, getCookie } from '../../helpers/cookies';
import { hasValue } from '../../helpers/hasValue';
import { useCookie } from '../../hooks/useCookie';
import { changeCurrentLocale } from '../../modules/framework/changeCurrentLocale';
import { useFramework } from '../../modules/framework/useFramework';
import { Notification } from '../Notification/Notification';
import { translations } from './bannerMessage';

export interface Props {
    enableSwitchLanguagePrompt: boolean;
}

export const PREFERRED_LANGUAGE_COOKIE = 'PreferredLanguage';
const SESSION_LANGUAGE_COOKIE = 'LangMessage';
const SHOULD_REDIRECT_TO_PREFERRED_LANG = true;

export const getBrowserLanguage = () => {
    if (typeof navigator === `undefined`) {
        return null;
    }
    // we need to split by , and ; to cover .split(/,|;/)
    // example: en-CA,en;q=0.8,en-US;q=0.6,de-DE;q=0.4,de;q=0.2
    const lang_raw = navigator && navigator.language && navigator.language.split(/,|;/)[0];
    const lang = lang_raw ? lang_raw.toLowerCase() : '';
    // check if we have exact match
    if (isLocaleCode(lang)) {
        return lang;
    }
    // split it by -|_ 0 is language, 1 can be script/region, 2 can be region (if has script)
    const lang_region = lang.split(/_|-/)[0];
    // check if we have just region
    if (isLocaleCode(lang_region)) {
        return lang_region;
    }
    // check if is special case pt-br (we return this even for just pt)
    if (lang_region === 'pt') {
        return 'pt-br';
    }
    return null;
};

export const setPreferredLanguage = (lang: string | undefined) => {
    const d = new Date();
    d.setTime(d.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
    setCookie({
        cookieName: PREFERRED_LANGUAGE_COOKIE,
        cookieValue: lang,
        expirationDate: d.toUTCString(),
        path: '/',
        samesite: CookieSameSiteAttribute.Lax,
        secure: true,
    });
};

export const setLanguageMessageCookie = () => {
    setCookie({
        cookieName: SESSION_LANGUAGE_COOKIE,
        cookieValue: '1',
        path: '/',
        samesite: CookieSameSiteAttribute.Lax,
        secure: true,
    });
};

export const PreferredLanguage = ({ enableSwitchLanguagePrompt = true }: Props) => {
    const {
        currentUrl,
        routing: { navigate },
    } = useFramework();
    const [preferredLocale] = useCookie(PREFERRED_LANGUAGE_COOKIE);
    const browserLocale = getBrowserLanguage();
    const [showNotification, setShowNotification] = useState(false);
    const [showWPNotification, setShowWPNotification] = useState(
        isWP(currentUrl.pathname) &&
            currentUrl.locale !== defaultLocale.code &&
            hasValue(preferredLocale),
    );

    const seenMessage = () => {
        setLanguageMessageCookie();
        setShowNotification(false);
    };

    const handlePreferredLanguage = (browserLocale: string) => {
        setPreferredLanguage(browserLocale);
        changeCurrentLocale(currentUrl, navigate, browserLocale, 'l10n_discovery_bnr');
    };

    const languageLink = (languageClickName: string) => {
        return (
            <button
                type="button"
                className="cursor-pointer underline hover:no-underline focus:no-underline"
                onClick={() => handlePreferredLanguage(browserLocale as string)}
            >
                {languageClickName}
            </button>
        );
    };

    const localizedMessages = (locale: string) => {
        const { message, here } = translations[locale as keyof typeof translations];
        // dirty way to bind the link where it should be without issues wherever it is
        const [start, end = ''] = message.text
            .replace(/\$\{(\s|)languageLink(\s|)\}/, '##link##')
            .split('##link##');
        if (localeCodes.includes(locale)) {
            return (
                <>
                    {start}
                    {languageLink(here.text)}
                    {end}
                </>
            );
        }
    };

    const messageContent =
        (browserLocale && hasValue(browserLocale) && localizedMessages(browserLocale)) || null;

    useEffect(() => {
        if (!enableSwitchLanguagePrompt) return;
        // when we have preferred currentUrl.locale we redirect to it, no redirect for WP
        // no redirects for !enable_switch_language_prompt
        // check if is not already current and we have it
        if (
            preferredLocale &&
            preferredLocale !== currentUrl.locale &&
            isLocaleCode(preferredLocale) &&
            !isWP(currentUrl.pathname)
        ) {
            SHOULD_REDIRECT_TO_PREFERRED_LANG &&
                changeCurrentLocale(currentUrl, navigate, preferredLocale, 'auto_l10n_redirect');
        }

        // check if conditions for dispalying message are met
        const isMessage = (currentLocale: string, preferredLocale?: string) => {
            const browserLanguage = getBrowserLanguage();
            // message was seen
            if (getCookie(SESSION_LANGUAGE_COOKIE)) return false;
            // visitor already has preferred currentUrl.locale set

            if (preferredLocale && isLocaleCode(preferredLocale)) return false;

            // we display message only if there is no cookie language, and browser lang is different then landing page lang and we need to have the browser language
            if (hasValue(browserLanguage) && browserLanguage !== currentLocale) return true;

            return false;
        };

        setShowNotification(isMessage(currentUrl.locale, preferredLocale));
    }, [
        currentUrl,
        currentUrl.locale,
        currentUrl.pathname,
        enableSwitchLanguagePrompt,
        navigate,
        preferredLocale,
    ]);

    if (!enableSwitchLanguagePrompt) {
        return null;
    }

    if (isWP(currentUrl.pathname)) {
        return (
            <Notification
                show={showWPNotification}
                icon={FlagIcon}
                content={c('OnlyEnglishPageMessage')
                    .t`We are sorry! This page is available only in English.`}
                onClose={() => setShowWPNotification(false)}
                onTimeOver={() => setShowWPNotification(false)}
            />
        );
    }

    return (
        <Notification
            show={showNotification}
            content={messageContent}
            onClose={() => seenMessage()}
            onTimeOver={() => seenMessage()}
            timeout={30_000}
        />
    );
};
