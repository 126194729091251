import type { ReactNode } from 'react';
import { Link, navigate } from 'gatsby';
import { defaultLocale } from '@protonme/localization';
import { getProtonUrl } from '@protonme/routing';
import Zendesk from '@protonme/shared/components/zendesk/components/Zendesk';
import { AffiliateCookie } from '@protonme/shared/modules/affiliate/AffiliateCookie';
import { AnalyticsBeacon } from '@protonme/shared/modules/analytics-beacon/AnalyticsBeacon';
import { UnleashProvider } from '@protonme/shared/modules/feature-flags';
import {
    defaultFrameworkValue,
    defaultLocalizationContext,
} from '@protonme/shared/modules/framework/default';
import { FrameworkProvider } from '@protonme/shared/modules/framework/gatsby/FrameworkContext';
import type { LocalizationContext, RoutingState } from '@protonme/shared/modules/framework/types';
import type { WindowLocation } from '@reach/router';
import { useInitTtag } from '@protonme/shared/hooks/useInitTtag';

const noTranslations = ['en'];

const getDefaultLocalizationContext = (url: string): LocalizationContext => {
    const protonUrl = getProtonUrl(url);

    if (protonUrl?.defaultUrl.pathname === '/jpmorgan') {
        return { alternateLanguages: noTranslations };
    }

    return defaultLocalizationContext;
};

export type PageContext = {
    locale?: string;
    translations?: string;
    alternateLanguages?: string[];
};

type Props = {
    pageContext: PageContext;
    location: WindowLocation;
    children: ReactNode;
};

const PageProviders = ({
    pageContext: { locale = defaultLocale.code, translations, alternateLanguages },
    location,
    children,
}: Props) => {
    useInitTtag(locale, translations);

    // Safe url creation, location.href can be undefined
    const currentUrl = location.href || location.pathname || '/';
    const routing = {
        internalLink: Link,
        navigate,
        state: location.state as RoutingState,
    };
    const localization = getDefaultLocalizationContext(currentUrl);
    if (alternateLanguages) {
        localization.alternateLanguages = alternateLanguages;
    }

    return (
        <FrameworkProvider
            currentUrl={currentUrl}
            routing={routing}
            localization={localization}
            unleash={undefined}
            cdn={defaultFrameworkValue.cdn}
        >
            <UnleashProvider>
                <AffiliateCookie />
                {children}
                <AnalyticsBeacon />
                <Zendesk locale={locale} url={location.href} />
            </UnleashProvider>
        </FrameworkProvider>
    );
};

export default PageProviders;
