import { LocaleBannerMessage } from '@protonme/localization';

export const translations: LocaleBannerMessage = {
    en: {
        message: {
            text: 'To see this page in English, click ${languageLink}.',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'here',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    de: {
        message: {
            text: 'Um diese Seite auf Deutsch zu sehen, klicken Sie ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'hier',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    cs: {
        message: {
            text: 'Chcete-li zobrazit tuto stránku v češtině, klikněte ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'zde',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    'es-419': {
        message: {
            text: 'Para ver esta página en español, haga clic ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'aquí',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    'es-es': {
        message: {
            text: 'Para ver esta página en español, haz clic ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'aquí',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    fr: {
        message: {
            text: 'Pour voir cette page en français, cliquez ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'ici',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    it: {
        message: {
            text: 'Per vedere questa pagina in italiano, clicca ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'qui',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    ja: {
        message: {
            text: 'このページを日本語で見るには、 ${languageLink} をクリックしてください。',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'ここ',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    ko: {
        message: {
            text: '이 페이지를 한국어로 보려면 ${languageLink}를 클릭하세요.',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: '여기',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    nl: {
        message: {
            text: 'Om deze pagina in het Nederlands te zien, klikt u ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'hier',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    pl: {
        message: {
            text: 'Aby zobaczyć tę stronę po polsku, kliknij ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'tutaj',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    'pt-br': {
        message: {
            text: 'Para ver esta página em português, clique ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'aqui',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    ru: {
        message: {
            text: 'Чтобы увидеть эту страницу на русском, нажмите ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'здесь',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    tr: {
        message: {
            text: 'Bu sayfayı Türkçe görmek için ${languageLink} tıklayın',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'buraya',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    da: {
        message: {
            text: 'For at se denne side på dansk, klik ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'her',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    fi: {
        message: {
            text: 'Nähdäksesi tämän sivun suomeksi, napsauta ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'tässä',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    pt: {
        message: {
            text: 'Para ver esta página em português, clique ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'aqui',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    ro: {
        message: {
            text: 'Pentru a vedea această pagină în română, faceți clic ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'aici',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
    sv: {
        message: {
            text: 'För att se denna sida på svenska, klicka ${languageLink}',
            crowdinContext: 'Full sentence: To see this page in <your own language>, click here',
        },
        here: {
            text: 'här',
            crowdinContext:
                'link for sentence: To see this page in <your own language>, click here',
        },
    },
};
