import type { Product } from '@protonme/apps';

export const products = ['business', 'calendar', 'drive', 'mail', 'pass', 'vpn'];

type productConstantLinksType = {
    CHROME_WEB_STORE?: string;
    FIREFOX_ADD_ONS?: string;
    SAFARI_APP_STORE?: string;
    GOOGLE_PLAY?: string;
    APP_STORE?: string;
    ANDROID?: string | Record<string, string>;
    IOS?: string | Record<string, string>;
    WINDOWS?: string | Record<string, string>;
    MACOS?: string | Record<string, string>;
    LINUX?: string | Record<string, string>;
};

export const DOWNLOAD_LINKS: Record<string, productConstantLinksType> = {
    MAIL: {
        ANDROID: 'https://proton.me/download/MailAndroid/ProtonMail-Android.apk',
        APP_STORE: 'https://apps.apple.com/us/app/proton-mail-encrypted-email/id979659905',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=ch.protonmail.android',
    },
    CALENDAR: {
        ANDROID: 'https://proton.me/download/CalendarAndroid/ProtonCalendar-Android.apk',
        APP_STORE: 'https://apps.apple.com/us/app/proton-calendar-secure-events/id1514709943',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=me.proton.android.calendar',
    },
    DRIVE: {
        ANDROID: 'https://proton.me/download/DriveAndroid/ProtonDrive-Android.apk',
        APP_STORE: 'https://apps.apple.com/us/app/proton-drive-cloud-storage/id1509667851',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=me.proton.android.drive',
    },
    PASS: {
        APP_STORE: 'https://apps.apple.com/us/app/proton-pass-password-manager/id6443490629',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=proton.android.pass',
        CHROME_WEB_STORE:
            'https://chrome.google.com/webstore/detail/proton-pass-free-password/ghmbeldphafepmbegfdlkpapadhbakde',
        FIREFOX_ADD_ONS: 'https://addons.mozilla.org/en-US/firefox/addon/proton-pass/',
        SAFARI_APP_STORE: 'https://apps.apple.com/app/id6502835663',
    },
    BRIDGE: {
        MACOS: 'https://proton.me/download/bridge/Bridge-Installer.dmg',
        WINDOWS: 'https://proton.me/download/bridge/Bridge-Installer.exe',
    },
    IMPORT_EXPORT: {
        LINUX: 'https://proton.me/download/ie/PKGBUILD',
        MACOS: 'https://proton.me/download/ie/Import-Export-app.dmg',
        WINDOWS: 'https://proton.me/download/ie/Import-Export-app-installer.exe',
    },
    VPN: {
        ANDROID: {
            CHROMEBOOK: 'https://play.google.com/store/apps/details?id=ch.protonvpn.android',
            FDROID: 'https://f-droid.org/en/packages/ch.protonvpn.android/',
            GITHUB: 'https://protonvpn.com/blog/download-android-app-on-github/',
        },
        APP_STORE: 'https://apps.apple.com/us/app/proton-vpn-fast-secure/id1437005085',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=ch.protonvpn.android',
        LINUX: 'https://protonvpn.com/support/linux-vpn-setup/',
        CHROME_WEB_STORE:
            'https://chromewebstore.google.com/detail/proton-vpn-a-swiss-vpn-yo/jplgfhpmjnbigmhklmmbgecoobifkmpa',
        FIREFOX_ADD_ONS:
            'https://addons.mozilla.org/en-US/firefox/addon/proton-vpn-firefox-extension/',
    },
};

export const INTERNAL_LINKS = {
    HOME: '/',
    PRICING: '/pricing',
    BUSINESS: '/business',
    BUSINESS_PLANS: '/business/plans',
    FAMILY: 'https://proton.me/family',

    MAIL: 'https://proton.me/mail',
    MAIL_PRICING: 'https://proton.me/mail/pricing',
    MAIL_SUPPORT: 'https://proton.me/support/mail',
    MAIL_B2B: 'https://proton.me/business/mail',

    BRIDGE: 'https://proton.me/mail/bridge',

    CALENDAR: 'https://proton.me/calendar',
    CALENDAR_SUPPORT: 'https://proton.me/support/calendar',

    DRIVE: 'https://proton.me/drive',
    DRIVE_PRICING: 'https://proton.me/drive/pricing',
    DRIVE_SIGN_UP: 'https://proton.me/drive/pricing',
    DRIVE_SUPPORT: 'https://proton.me/support/drive',
    DRIVE_B2B: 'https://proton.me/business/drive',

    VPN: 'https://protonvpn.com',
    VPN_B2B: 'https://proton.me/business/vpn',

    PASS: 'https://proton.me/pass',
    PASS_PRICING: 'https://proton.me/pass/pricing',
    PASS_BUSINESS: 'https://account.proton.me/pass/signup/business',
    PASS_SUPPORT: 'https://proton.me/support/pass',
    PASS_B2B: 'https://proton.me/business/pass',

    WALLET: 'https://proton.me/wallet',
    WALLET_PRICING: 'https://account.proton.me/wallet/signup', // Temporarily set the pricing url to the signup url until we have a dedicated pricing page, sometime after launch
    WALLET_SUPPORT: 'https://proton.me/support/wallet',
} as const;

export const PRODUCT_LINKS: Record<Product, string> = {
    mail: INTERNAL_LINKS.MAIL,
    calendar: INTERNAL_LINKS.CALENDAR,
    drive: INTERNAL_LINKS.DRIVE,
    vpn: INTERNAL_LINKS.VPN,
    pass: INTERNAL_LINKS.PASS,
    wallet: INTERNAL_LINKS.WALLET,
} as const;

export const BLOG_LINKS = {
    HOME: '/blog',
    PRODUCTS: '/blog/product-updates',
    MAIL: '/blog/product-updates/proton-mail',
    DRIVE: '/blog/product-updates/proton-drive',
    PASS: '/blog/product-updates/proton-pass',
    CALENDAR: '/blog/product-updates/proton-calendar',
    WALLET: '/blog/product-updates/proton-wallet',
    VPN: 'https://protonvpn.com/blog/service-updates',
    SIMPLE_LOGIN: 'https://simplelogin.io/blog/',
    PROTON_NEWS: '/blog/news',
    PRIVACY_NEWS: '/blog/privacy-news',
    GUIDES: '/blog/privacy-guides',
    OPINION: '/blog/opinion',
    BUSINESS: '/blog/business',
    FEED: '/blog/feed',
} as const;

export const RESOURCES = {
    FACEBOOK: 'https://www.facebook.com/Proton',
    INSTAGRAM: 'https://www.instagram.com/protonprivacy/',
    LINKEDIN: 'https://www.linkedin.com/company/protonprivacy/',
    MASTODON: 'https://mastodon.social/@protonprivacy',
    WHATSAPP: 'https://www.whatsapp.com/channel/0029VaErt8I4tRrjiU8eMd1U',
    THREADS: 'https://www.threads.net/@protonprivacy',
    TELEGRAM: 'https://t.me/proton_privacy',
    USERVOICE: 'https://protonmail.uservoice.com/',
    TIKTOK: 'https://www.tiktok.com/@privacytok',
    REDDIT: {
        PME: 'https://www.reddit.com/r/ProtonMail/',
        VPN: 'https://www.reddit.com/r/ProtonVPN/',
    },
    TWITTER: {
        PME: 'https://x.com/intent/user?screen_name=ProtonPrivacy',
        VPN: 'https://x.com/intent/user?screen_name=ProtonVPN',
    },
    WIKIPEDIA: 'https://en.wikipedia.org/wiki/ProtonMail',
    YOUTUBE: 'https://www.youtube.com/@ProtonAG?sub_confirmation=1',
};

export const FALLBACK_ORIGIN = (() => {
    switch (process.env.WEBSITE) {
        case 'proton-me':
            return 'https://proton.me';
        case 'protonvpn-com':
            return 'https://protonvpn.com';
        default:
            throw new Error(
                `ERROR [@protonme/routing]: Unhandeled process.env.WEBSITE of "${process.env.WEBSITE}". Make sure env variable WEBSITE is set and handeled.`,
            );
    }
})();
