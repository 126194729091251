import { fetchHelper } from '../../helpers/fetchHelper';
import type { SlimAPI_CoreReportsBug, SlimAPI_CoreReportsBugRequestBody } from './types';

/**
 * @api /api/core/v4/reports/bug
 * @description Proton Slim API to send bug formular.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/core/#tag/Reports/operation/post_core-%7B_version%7D-reports-bug
 */
export const postCoreReportsBug = async (body: SlimAPI_CoreReportsBugRequestBody) => {
    const response = await fetchHelper('/core/v4/reports/bug', {
        method: 'POST',
        body: JSON.stringify(body),
    });

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_CoreReportsBug = await response.json();
    return json;
};
