import { fetchHelper } from '../../helpers/fetchHelper';
import { timeoutPromise } from '../../helpers/timeoutPromise';
import type {
    SlimAPI_PaymentsSubscriptionCheck,
    SlimAPI_PaymentsSubscriptionCheckRequestBody,
} from './types';

const isServer = typeof sessionStorage === 'undefined';
const status: Record<string, 'PENDING' | 'RESOLVED' | 'FAILURE'> = {};

/**
 * @api /api/payments/v5/subscription/check
 * @description Proton Slim API response containing discount information for the requested PlanID/CouponCode/Cycle.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/payments/#tag/Subscriptions/operation/post_payments-v4-subscription-check
 */
export const putPaymentsSubscriptionCheck = async (
    body: SlimAPI_PaymentsSubscriptionCheckRequestBody,
): Promise<SlimAPI_PaymentsSubscriptionCheck> => {
    // On server just request without caching
    if (isServer) {
        return request(body);
    }

    // On client only request once and cache in session storage
    const key = `subscription.check.${body.Currency}.${body.Cycle}.${Object.keys(body.Plans).join('&')}.${body.Codes.join('&')}`;
    const storedCoupon = sessionStorage.getItem(key);

    if (!storedCoupon && !status[key]) {
        status[key] = 'PENDING';
        const json = await request(body).catch((error) => {
            status[key] = 'FAILURE';
            throw error;
        });
        sessionStorage.setItem(key, JSON.stringify(json));
        status[key] = 'RESOLVED';
        return json;
    }

    const getStoredPlans = async (): Promise<SlimAPI_PaymentsSubscriptionCheck> => {
        if (status[key] === 'PENDING') {
            await timeoutPromise(150);
            return await getStoredPlans();
        }

        const storedCoupon = sessionStorage.getItem(key);
        if (!storedCoupon) {
            throw new Error(
                '[@protonme/slim-api] failed to load subscription.check from session storage',
            );
        } else {
            return JSON.parse(storedCoupon);
        }
    };

    return await getStoredPlans();
};

const request = async (
    body: SlimAPI_PaymentsSubscriptionCheckRequestBody,
): Promise<SlimAPI_PaymentsSubscriptionCheck> => {
    const response = await fetchHelper('/payments/v5/subscription/check', {
        method: 'PUT',
        body: JSON.stringify(body),
    });

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_PaymentsSubscriptionCheck = await response.json();
    return json;
};
