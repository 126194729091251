import { fetchHelper } from '../../helpers/fetchHelper';
import { timeoutPromise } from '../../helpers/timeoutPromise';
import type { SlimAPI_PaymentsPlansDefault } from './types';

const isServer = typeof sessionStorage === 'undefined';
let status: undefined | 'PENDING' | 'RESOLVED' | 'FAILURE' = undefined;

/**
 * @api /api/payments/v5/plans/default
 * @description Proton Slim API response containing the free plan.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/payments/#tag/Plans/operation/get_payments-v4-plans-default
 */
export const getPaymentsPlansDefault = async (): Promise<SlimAPI_PaymentsPlansDefault> => {
    // On server just request without caching
    if (isServer) {
        return request();
    }

    // On client only request once and cache in session storage
    const key = `plans.default`;
    const storedPlansDefault = sessionStorage.getItem(key);

    if (!storedPlansDefault && !status) {
        status = 'PENDING';
        const json = await request().catch((error) => {
            status = 'FAILURE';
            throw error;
        });
        sessionStorage.setItem(key, JSON.stringify(json));
        status = 'RESOLVED';
        return json;
    }

    const getStoredPlansDefault = async (): Promise<SlimAPI_PaymentsPlansDefault> => {
        if (status === 'PENDING') {
            await timeoutPromise(150);
            return await getStoredPlansDefault();
        }

        const storedPlansDefault = sessionStorage.getItem(key);
        if (!storedPlansDefault) {
            throw new Error(
                '[@protonme/slim-api] failed to load plans.default from session storage',
            );
        } else {
            return JSON.parse(storedPlansDefault);
        }
    };

    return await getStoredPlansDefault();
};

const request = async (): Promise<SlimAPI_PaymentsPlansDefault> => {
    const response = await fetchHelper('/payments/v5/plans/default');

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_PaymentsPlansDefault = await response.json();
    return json;
};
