export const hasValue = (value: unknown): boolean => {
    if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        value !== 'none' &&
        value !== '<br/>'
    ) {
        return typeof value === 'string' ? (value || '').replace(/<[^>]*[^/]>/g, '') !== '' : true;
    }
    return false;
};
