import { useContext } from 'react';
import { useStore } from '@nanostores/react';
import { $frameworkStore } from './astro/FrameworkStore';
import { defaultFrameworkValue } from './default';
import { FrameworkContext } from './gatsby/FrameworkContext';
import type { FrameworkValue } from './types';

const renameProps = (value: FrameworkValue) => {
    const { currentUrl, routing, localization, unleash, cdn } = value;
    return {
        currentUrl,
        routing,
        localization,
        unleash,
        cdn,
    };
};

export const useFramework = () => {
    const contextValue = useContext(FrameworkContext);
    const storeValue = useStore($frameworkStore);

    if (contextValue !== defaultFrameworkValue) {
        return renameProps(contextValue);
    }

    return renameProps(storeValue);
};
