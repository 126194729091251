import { defaultLocale, localesPme, localesVpn } from '@protonme/localization';
import { getDefaultPathname } from './getDefaultPathname';
import { getProduct } from './getProduct';
import { type Websites, getWebsite } from './getWebsite';
import { testIsB2B } from './testIsB2B';
import { testIsDownload } from './testIsDownload';
import { testIsPreview } from './testIsPreview';
import { testIsPricing } from './testIsPricing';

const contexts = ['', 'blog', 'support'];

const getLocalesWithoutDefaultCode = (website: Websites) =>
    (website?.includes('protonvpn-com') ? localesVpn : localesPme)
        .slice(1)
        .map((locale) => locale.code);

export const parseProtonUrl = (protonUrl: URL) => {
    const parts = protonUrl.pathname.split('/').filter((part) => part !== '');

    const context = contexts.find((part) => part === parts[0]);
    const website = getWebsite(protonUrl, context);

    const localesWithoutDefaultCode = getLocalesWithoutDefaultCode(website);
    const localeIndex = context ? 1 : 0;
    const isLocalized = localesWithoutDefaultCode.includes(parts[localeIndex]);
    const locale = isLocalized ? parts[localeIndex] : defaultLocale.code; // works as long as projects share same default locale ;)

    const restIndex = isLocalized ? localeIndex + 1 : localeIndex;
    const restParts = parts.slice(restIndex);

    const defaultPathname = getDefaultPathname(restParts, context);
    const defaultUrl = new URL(
        defaultPathname + protonUrl.search + protonUrl.hash,
        protonUrl.origin,
    );

    const product = getProduct(restParts, website);

    const isCurrentOrigin = !!(process.env.WEBSITE && website?.includes(process.env.WEBSITE));
    const isB2B = testIsB2B(website, restParts);
    const isPricing = testIsPricing(restParts);
    const isPreview = testIsPreview(restParts);
    const isDownload = testIsDownload(restParts);

    return {
        locale,
        defaultUrl,
        website,
        product,
        isCurrentOrigin,
        isB2B,
        isPricing,
        isDownload,
        isPreview,
    };
};
